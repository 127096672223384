<div class="splash-container">
    <div class="card login-section">
      
        <div class="text-center p-3">
            <a href="../index.html">
                <img class="logo-img" src="../assets/images/logo.png" alt="logo"></a>
          
        </div>
        <div class="card-body">
            <form [formGroup]="resetPassword">
               
                <div class="form-group">
                    <input class="form-control form-control-lg" type="password" formControlName="password" placeholder="New Password">
                    <div class="text-danger" *ngIf="resetPassword.get('password').invalid && (resetPassword.get('password').dirty || resetPassword.get('password').touched)">
                        <span *ngIf="resetPassword.get('password').hasError('required')">*New password is
                            required.</span>
                        <span *ngIf="resetPassword.get('password').hasError('pattern')">*Password should have minimum 8
                            characters, at least 1 uppercase letter, 1 lowercase letter, 1 number and 1 special
                            character.</span>
                    </div>
                </div>
                <div class="form-group">
                    <input class="form-control form-control-lg" type="password" formControlName="confirmPassword" placeholder="Confirm New Password">
                    <div *ngIf="resetPassword.get('confirmPassword').dirty" class="text-danger">
                        <span *ngIf="resetPassword.get('confirmPassword').value != resetPassword.get('password').value">*Password
                            and confirm password should be match.</span>
                    </div>
                </div>
                <button type="submit" class="btn bg-green btn-lg btn-block font-bold btn-green br15" [disabled]="!resetPassword.valid" (click)="resetPasswordApi()">Update</button>
                <div class="text-center mt-2">
                    <a [routerLink]="['/login']">Back to login</a>

                </div>
            </form>
        </div>
    </div>
</div>