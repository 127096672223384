<div class="splash-container">
    <div class="card login-section">
        <div class="login_box_outer">

          
            <div class="text-center p-3">
                <a href="../index.html">
                    <img class="logo-img" src="../assets/images/logo.png" alt="logo"></a>
                <span class="splash-description pt-2">Please enter your email address to reset your password.</span>
            </div>
            <div class="card-body">
                <form [formGroup]="forgetform">
                    <div class="form-group">
                        <input class="form-control form-control-lg" id="username" type="text" placeholder="Email" formControlName="email" autocomplete="off" maxlength="60">
                        <div class="text-danger" *ngIf="forgetform.get('email').invalid && (forgetform.get('email').dirty || forgetform.get('email').touched)">
                            <span *ngIf="forgetform.get('email').hasError('required')">*Email address is
                                required.</span>
                            <span *ngIf="forgetform.get('email').hasError('pattern')">*Please enter valid email
                                address.</span>
                        </div>
                    </div>

                    <button type="submit" class="btn bg-green btn-lg btn-block font-bold btn-green br15" [disabled]="!forgetform.valid" (click)="verfyEmail()">Submit</button>
                    <div class="text-center mt-2">
                        <a [routerLink]="['/login']">Back to login</a>

                    </div>
                </form>

            </div>
        </div>
    </div>
</div>