


<form  class="digit-group" data-group-name="digits" data-autosubmit="false" autocomplete="off">
    <ng-container *ngFor="let item of arr;let i = index;let f = first; let l = last">
        <input [type]="inputType" *ngIf="f" [(ngModel)]="digit[i]" maxlength="1" (input)="getOtp()" (keypress)="commonService.numberOnly($event)" [ngModelOptions]="{standalone: true}" [attr.id]="'digit-'+(i+1)" [attr.name]="'digit-'+(i+1)" [attr.data-next]="'digit-'+(i+1 + 1)" />
        <input [type]="inputType" *ngIf="!f && !l" [(ngModel)]="digit[i]" maxlength="1"  (input)="getOtp()" (keypress)="commonService.numberOnly($event)" [ngModelOptions]="{standalone: true}" [attr.id]="'digit-'+(i+1)" [attr.name]="'digit-'+(i+1)" [attr.data-next]="'digit-'+(i+1 + 1)" [attr.data-previous]="'digit-' + i" />
        <input [type]="inputType"  *ngIf="l" [(ngModel)]="digit[i]" maxlength="1" (input)="getOtp()" (keypress)="commonService.numberOnly($event)" [ngModelOptions]="{standalone: true}" [attr.id]="'digit-'+(i+1)" [attr.name]="'digit-'+(i+1)"  [attr.data-previous]="'digit-' + i" /> 
   
    </ng-container>
	
	<!-- <input type="text" [(ngModel)]="digit2" id="digit-2" name="digit-2" data-next="digit-3" data-previous="digit-1" />
	<input type="text" [(ngModel)]="digit3" id="digit-3" name="digit-3" data-next="digit-4" data-previous="digit-2" />
	
	<input type="text" [(ngModel)]="digit4" id="digit-4" name="digit-4"  data-previous="digit-3" /> -->

</form>