<div class="splash-container">
    <div class="card ">
       
        <div class="card-body">
            <form [formGroup]="changePasswordForm">
                <div class="form-group">
                    <input class="form-control form-control-lg" type="password" placeholder="Old password" formControlName="oldPassword" autocomplete="off">
                    <div *ngIf="changePasswordForm.get('oldPassword').invalid && (changePasswordForm.get('oldPassword').touched || changePasswordForm.get('oldPassword').dirty)" class="text-danger">
                        <span *ngIf="changePasswordForm.get('oldPassword').hasError('required')">*Please enter old password.</span>
                    </div>
                </div>
                <div class="form-group">
                    <input class="form-control form-control-lg" type="password" formControlName="newPassword" placeholder="New Password">
                    <div *ngIf="changePasswordForm.get('newPassword').invalid && (changePasswordForm.get('newPassword').touched || changePasswordForm.get('newPassword').dirty)" class="text-danger">
                        <span *ngIf="changePasswordForm.get('newPassword').hasError('required')">*Please enter new password.</span>
                        <span *ngIf="changePasswordForm.get('newPassword').hasError('pattern')">
                            *Password should have minimum 8
                            characters, at least 1 uppercase letter, 1 lowercase letter, 1 number and 1 special
                            character.
                        </span>
                    </div>
                </div>
                <div class="form-group">
                    <input class="form-control form-control-lg" type="password" formControlName="confirmPassword" placeholder="confirm new password">
                    <div *ngIf="changePasswordForm.get('confirmPassword').dirty" class="text-danger">
                        <span *ngIf="changePasswordForm.get('confirmPassword').value != changePasswordForm.get('newPassword').value">*Password
                            and confirm password should match.</span>
                    </div>
                </div>
                <button type="submit" class="btn bg-green btn-lg btn-block" [disabled]="changePasswordForm.invalid" (click)="changePassword()" style="color: white;">Update</button>
                <button type="submit" class="btn bg-green btn-lg btn-block" (click)="back()" style="color: white;">Back</button>

            </form>
        </div>
    </div>
</div>